<template>
    <div>
        <Modal
            v-model="modal_add_sport"
            title="编辑路由"
            @on-ok='addNewSport'
        >
            <sportSearch  v-model="new_sport" :season_id="data.season.id" > </sportSearch>
        </Modal>

        <Spin size="large" fix v-if="spinShow"></Spin>
        <Tabs value="1">
            <TabPane label="基础信息" name="1">
            <Form label-position="left" :label-width="80" >
                <FormItem label="id: " v-if="data.id">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.id" disabled></Input>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="奥运会: ">
                    <Row>
                        <Col span="20" >
                            <seasonSearch v-model="data.season" ></seasonSearch>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="中文名称: ">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.name_zh"></Input>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="英文名称: ">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.name_en"></Input>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="中文地址: ">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.address"></Input>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="英文地址: ">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.address_en"></Input>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="封面: ">
                    <Row>
                        <Col span="20" >
                            <imgEditor v-model="data.cover_pic" :prefix="$conf.olympics.img_prefix" 
                                dir="olympics" :previewButton='true' :clearButton='true'>
                            </imgEditor>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="介绍图片: ">
                    <Row>
                        <Col span="20" >
                            <imgEditor v-model="data.introduce_pic" :prefix="$conf.olympics.img_prefix" 
                                dir="olympics" :previewButton='true' :clearButton='true'>
                            </imgEditor>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="描述: ">
                    <Row>
                        <Col span="20" >
                            <Input type="textarea" v-model="data.description" ></Input>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="运动项目: ">
                    <Row v-for="(item, index) in data.sports" :key="index" >
                        <Col span="22" >
                            <Row>
                                <Col span="8" >
                                    <span class="text-main"> {{item.name}} </span>
                                </Col>
                                <Col span="12" >
                                    <span class="text-link"> {{item.id}} </span>
                                </Col>
                                <Col span="4" >
                                    <Icon type="md-close-circle"  @click.native="data.sports.splice(index, 1)" />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col span="20" >
                            <Button shape="circle" icon="md-add" @click="modal_add_sport=true" ></Button>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="容量描述: ">
                    <Row v-for="(item, index) in data.info" :key="index" > 
                        <Col span="22" > 
                            <Form label-position="left" :label-width="50" > 
                                <FormItem label="描述: " > 
                                    <Row> 
                                        <Input v-model="item.desc" ></Input> 
                                    </Row> 
                                </FormItem> 
                                <FormItem label="容量: " > 
                                    <Row> 
                                        <Input v-model="item.capacity" ></Input> 
                                    </Row> 
                                </FormItem> 
                                <FormItem label="删除: " > 
                                    <Row> 
                                        <Icon type="md-close-circle"  @click.native="data.info.splice(index, 1)" /> 
                                    </Row> 
                                </FormItem> 
                            </Form> 
                        </Col>
                    </Row>
                    <Row>
                        <Col span="20" >
                            <Button shape="circle" icon="md-add" @click="data.info.push({'desc': '','capacity': ''})" ></Button>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="是否删除: ">
                    <Row>
                        <Col span="20" >
                            <Select v-model="data.deleted" >
                                <Option :value="1" key="1">是</Option>
                                <Option :value="0" key="0">否</Option>
                            </Select>
                        </Col>
                    </Row>
                </FormItem>
            </Form>
            </TabPane>
        </Tabs>
        <Row align="middle">
            <Button type="primary"  @click="save" long size="default">保存</Button>
        </Row>
    </div>
</template>
<script>

import { getVenueList, updateVenue } from '@/api/olympics/venue';
import seasonSearch from '../season/search.vue';
import sportSearch from '../sport/search.vue';
import imgEditor from '@/components/img-editor/img-editor.vue';

export default {
    name: "venue-edit",
    props: {
        _id: Number,   // id
    },
    components: {
        seasonSearch,
        sportSearch,
        imgEditor,
    },
    data () {
        let self = (window.x = this)
        return {
            type: 1, 
            spinShow: false,
            data: {
                id: 0,
                season: {},
                sports: [],
                info:   [],
            },
            new_sport: {
                "id": 0,
                "name": '',
            },
            modal_add_sport: false,
        }
    },
    methods: {
        // 获取联赛分类
        getData () {
            let self = this;
            if (self._id && self._id > 0){
                this.spinShow = true;
                // 搜索日期
                var params = {
                    "id" : self._id,
                }
                getVenueList( params ).then(response => {
                    if (response.data.code == 0) {
                        if (response.data.data.list && response.data.data.list.length > 0){
                            this.data = response.data.data.list[0];
                        }else{
                            this.data = {};
                        }
                        self.spinShow = false;
                    } else {
                        self.$Message.error(response.data.msg);
                    }
                })
            }else{
                self.data = {
                    id : 0,
                    season: {},
                    sports: [],
                    info:   [],
                }
            }
        },
        // end 
        emit(func_name){
            this.$emit('closePage', func_name);
        },

        save() {
            let self = this;
            let sport_ids = [];
            self.data.sports.forEach(element => {
                if (element.id > 0 && sport_ids.indexOf(element.id) == -1 ){
                    sport_ids.push( element.id )
                }
            });
            let params = {
                id: self.data.id,
                season_id: self.data.season.id,
                sport_ids: sport_ids,
                name_zh: self.data.name_zh,
                name_en: self.data.name_en,
                address: self.data.address,
                address_en: self.data.address_en,
                info: self.data.info || [],
                cover_pic: self.data.cover_pic,
                introduce_pic: self.data.introduce_pic,
                description: self.data.description,
                deleted: self.data.deleted,
            }
            // console.log(params);
            updateVenue(params).then(response => {
                if (response.data.code == 0) {
                    this.$Message.success("保存成功");
                    self.emit("save");
                } else {
                    this.$Message.error(response.data.msg)
                }
            })
        },
        // end
        addNewSport(){
            let self = this;
            if (self.new_sport.id > 0){
                self.data.sports.push( self.new_sport );
            }
            self.new_sport = {
                id: 0,
                name: '',
            }
        },
        // end 
    },
    mounted () {
        // let self = this;
    },
    watch: {
        "_id":{
            handler(value){
                // console.log( value );
                let self = this;
                self.getData();
            },
	        immediate: true,  // 深度触发
        }
    },
    computed: {
    }
}
</script>
