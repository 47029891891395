<template>
  <div>
    <Drawer
        v-model="drawer_edit"
        width="450"
        :mask-closable="true"
    >
        <venueEdit :_id="edit_id" @closePage="closeEditDrawer"></venueEdit>
    </Drawer>  
    <Row style="margin-bottom: 10px;" :gutter="16" align="middle">
        <Col span="4" style="text-align: left">
            <Input v-model="search_key" placeholder="搜索" search @on-search="onSearch" />
        </Col>
        <Col span="20" style="text-align: right">
            <Button type="success"  @click="add">新增</Button>
        </Col>
    </Row>
    <fitTable 
        :columns="columns" 
        :data="data" 
        :loading="loading" 
    >
    </fitTable>
    <Page
        :total="total_page"
        :current="current_page"
        :page-size="page_size"
        @on-change="onPageChange"
        show-total
        show-elevator
    ></Page>
  </div>
</template>
<script>

import { getVenueList } from '@/api/olympics/venue';
import venueEdit from './edit.vue';
import { getOlympicImgUrl } from '@/utils/util';
import fitTable from '../../basic/table.vue';

const editButton = (vm, h, params) => {
    let self = this;
    return h(
        'Button',
        {
            style: {
                margin: '2px 2px'
            },
            props: {
            },
            on: {
                click: () => {
                    vm.edit_id = params.row.id;
                    vm.drawer_edit = true;
                }
            }
        },
        '编辑'
    )
}

export default {
    name: "venue-list",
    props: {
    },
    components: {
        venueEdit,
        fitTable,
    },
    data () {
        let self = (window.x = this)
        return {
            drawer_edit: false,
            edit_id : 0,
            search_key: '',
            loading: true,
            total_page: 0,
            page_size: 0,
            current_page: 1,
            columns: [
                {
                    title: 'id',
                    align: 'center',
                    width: 80,
                    key: 'id',
                },
                {
                    title: '奥运会',
                    align: 'center',
                    key: 'season',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        var season = currentRow.season;
                        return h('span', season.name );
                    }
                },
                {
                    title: '运动项目',
                    align: 'left',
                    key: 'sports',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        let data_list = [];
                        currentRow.sports.forEach(element => {
                            data_list.push( 
                                h('Row', element.name),
                            )
                        });
                        if (data_list.length > 0){
                            return data_list
                        }else{
                            return h('span', ' ')
                        }
                    }
                },
                {
                    title: '中文名称',
                    align: 'center',
                    key: 'name_zh',
                    minWidth: 80,
                },
                {
                    title: '英文名称',
                    align: 'center',
                    key: 'name_en',
                    minWidth: 80,
                },
                {
                    title: '中文地址',
                    align: 'center',
                    key: 'address',
                    minWidth: 80,
                },
                {
                    title: '英文地址',
                    align: 'center',
                    key: 'address_en',
                    minWidth: 80,
                },
                {
                    title: '封面',
                    align: 'center',
                    key: 'cover_pic',
                    minWidth: 80,
                    render(h, params){
                        let currentRow = params.row;
                        return h("Row", {attrs: {justify: 'center' }}, 
                                [h( 'img', 
                                    { 
                                        class: 'long-pic',
                                        attrs: { src: getOlympicImgUrl( currentRow.cover_pic ), }, 
                                    }
                                )]
                        )
                    }
                },
                {
                    title: '介绍图片',
                    align: 'center',
                    key: 'introduce_pic',
                    minWidth: 80,
                    render(h, params){
                        let currentRow = params.row;
                        return h("Row", {attrs: {justify: 'center' }}, 
                                [h( 'img', 
                                    {
                                        class: 'long-pic', 
                                        attrs: { src: getOlympicImgUrl( currentRow.introduce_pic ), }, 
                                    }
                                )]
                        )
                    }
                },
                {
                    title: '描述',
                    align: 'center',
                    width: 300,
                    key: 'description',
                    render(h, params){
                        let currentRow = params.row;
                        return h('span', {class:"table-column-intro"}, currentRow.description );
                    }
                },
                {
                    title: '容量信息',
                    align: 'left',
                    key: 'info',
                    minWidth: 180,
                    render: function (h, params) {
                        let currentRow = params.row;
                        let data_list = [];
                        currentRow.info.forEach(element => {
                            data_list.push( 
                                h('Row', [
                                    h('span',
                                        {
                                            class: 'text-main',
                                        },
                                        element.desc + '  ',
                                    ),
                                    h('span',
                                        {
                                            class: 'text-link',
                                        },
                                        element.capacity,
                                    ),
                                ] ), 
                            )
                        });
                        if (data_list.length > 0){
                            return data_list
                        }else{
                            return h('span', ' ')
                        }
                    }
                },
                {
                    title: '操作',
                    key: 'handlers',
                    align: 'center',
                    width: 100,
                    render (h, params) {
                        return [ editButton( self, h, params) ]
                    }
                }
            ],
            data: [],
        }
    },
    methods: {
        onPageChange (page) {
            this.current_page = page
            this.getData()
        },
        // end
        onSearch (search_key) {
            this.current_page = 1;
            this.search_key = search_key;
            this.getData();
        },
        // end
        closeEditDrawer(){
            let self = this;
            self.drawer_edit = false;
            self.edit_id = 0;
            self.getData();
        },
        // end
        getData () {
            this.loading = true;
            var params = {
                search_key: this.search_key,
                page: this.current_page,
            }
            getVenueList(params).then(response => {
                if (response.data.code == 0) {
                    this.data = response.data.data.list;
                    this.total_page = response.data.data.total_page;
                    this.page_size = response.data.data.page_size;
                    this.current_page = response.data.data.current_page;
                    this.loading = false;
                } else {
                    this.$Message.error(response.data.msg);
                }
            })
        },
        // end 
        add () {
            this.edit_id = 0;
            this.drawer_edit = true;
        },
        // end 
    },
    mounted () {
        this.getData();
    },
    watch: { 
    }, 
    computed: {} 
}
</script>

<style scoped>
    @import "list.css";
</style>
