<template>
    <div>
        <venueList ></venueList>
    </div>
</template>
<script>

import venueList from '@/components/olympics/venue/list.vue';

export default {
    components: {
        venueList
    },
    data () {
        let self = (window.x = this)
        return {
            // sport_id: 17,
        }
    },
    methods: {
        demo () {
        },
        // end 
    },
    mounted () {
        // this.getData()
    },
    watch: {},
    computed: {}
}
</script>
